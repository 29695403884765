<template>
	<div class="tunnista">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../../assets/miesasetteleekuviaseinalle.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Tunnista oma osaamisesi ja kiinnostuksesi</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Osaaminen</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Osaaminen on moninainen kokonaisuus hankittuja tietoja ja taitoja ja sitä voi jaotella monin eri tavoin. Työelämässä yksi tapa on jakaa osaaminen yleisiin työelämätaitoihin, ammatilliseen osaamiseen ja itsensä johtamisen taitoihin. Yleiset työelämätaidot ovat missä tahansa työssä tai ammatissa tarvittavia taitoja, ammatillinen osaaminen on koulutuksen ja kokemuksen kautta syntynyttä osaamista ja itsensä johtamisen taidot ovat luontaisia taitoja, joita voi myös oppia.</p>
					<p>Työelämän ja oman osaamisen sanoittaminen on tärkeää työnhaussa. Osaatko tunnistaa ja sanoittaa mitä edellisissä työpaikoissa on tehty ja mitä osaamista niistä on kertynyt?</p>
					<p><b>Osaamisen tunnistaminen:</b> Selvitä, millaista osaamista sinulla on ja tuota osaamisen arvioinnissa tarvittavia materiaaleja ja todisteita osaamisesta.<br>
					<b>Osaamisen osoittaminen:</b> Tee oma osaamisesi aktiivisesti näkyväksi ja näytä se erilaisin tavoin.<br>
					<b>Osaamisen tunnustaminen:</b> Eri tavoin hankitun osaamisen muodollinen tai virallinen tunnustaminen, jossa osaamiselle myönnetään esimerkiksi todistus tai opintopisteitä. Kerää nämä kasaan.<br>
					<b>Validointi:</b> Elämäsi eri vaiheissa hankkimien tietojen, taitojen ja kompetenssien arviointi ja tunnustaminen.<br>
					Epävirallinen oppiminen: Virallisen koulujärjestelmän ulkopuolella tapahtuvaa järjestelmällistä ja tavoitteellista opiskelua, esim. opintokeskukset ja järjestöt.<br>
					<b>Arkioppiminen:</b> Löytyykö sinulta päivittäistä työhön, perheeseen tai vapaa-aikaan liittyvää oppimista, joka ei ole järjestelmällistä, mutta voi olla tarkoituksellista? Mitä se on?</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Kiinnostukset</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Mieti mikä sinulle on tärkeää? Jatkuvuus, vaihtuvuus, uuden oppiminen, vaikutusmahdollisuudet, oma aika, jne. Voit tarkastella asiaa niin työelämän kuin yksityiselämän kannalta, sillä väistämättä ne ovat vuorovaikutuksissa toisiinsa.<br>
					Mikä tuottaa sinulle iloa? Millaisista työtehtävistä pidät?</p>
					<p>Rutiininomainen, vaihtelu, yksintyöskentely, tiimityöskentely, projektityö, etätyö, esiintyminen ja puhuminen, matkustus, esimiestyö, jne.</p>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Mahdollisuudet</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Tunnista avoimen työtehtävän tarjoamat mahdollisuudet.<br> 
					Uuden oppiminen ja koulutusmahdollisuudet, työtehtävien laajuus (ovatko tehtävät pysyviä vai mahdollisesti laajenevia), etenemismahdollisuudet, jne.
					</p>
					<p>Työelämän mahdollisuuksien tunnistamiseksi on oman osaamisen tunnistamisesta apua. Näin sinulla on kirkkaampi käsitys tiedoista ja taidoistasi, mikä helpottaa arvioimaan omaa soveltuvuuttasi kyseiseen työtehtävään.</p>
				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import Social from '../../components/Social.vue'

export default {
name: 'Tunnista',
components: {
    Social
  }
}
</script>

<style type="text/css">
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>